import * as React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import linkResolver from "utils/linkresolver";
import styled from "styled-components";
import {VscLoading} from "react-icons/vsc";

const Loader = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 4rem;

	& h4 {
		border-bottom: none;
	}

	svg {
		animation: rotate 2s linear infinite;
		color: ${props => props.theme.colors.primary};
	}

	@keyframes rotate {
		0% {transform: rotate(0deg);}
		100% {transform: rotate(360deg);}
	}
`;


const PreviewPage = () => {
	return(
		<Loader>
			<VscLoading/>
			<h4>Loading...</h4>
		</Loader>
	)
};

export default withPrismicPreviewResolver(PreviewPage, [
	{
		repositoryName: process.env.GATSBY_PRISMIC_SITE_REPO,
		linkResolver,
	},
])
